import {createRouter, createWebHashHistory} from 'vue-router';

// 一级页面
const backendView = () => import("@/view/BackendView");
const toolPage = () => import("@/view/ToolView");
const homepageView = () => import("@/view/HomepageView");
const previewView = () => import("@/view/PreviewView");
const paymentView = () => import("@/view/PaymentView");

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'BackendView',
    component: backendView,
    children: [
      {
        path: "",
        name: 'homepageView',
        component: homepageView
      },
      {
        path:'/payment',
        name: 'paymentView',
        component: paymentView,
      },
      {
        path: '/manage', // 重定向到 /#/manage,
        name: 'manageView',
        component: () => import('@/view/ManagerView'),
        children: [
          {
            path: "jiaban", // 加班
            component: () => import('@/components/plugins/jiaban/JiabanManager')
          },
          {
            path: "voting", // 投票
            component: () => import('@/components/plugins/voting/VotingManager')
          },
          {
            path: "diange", // 点歌
            component: () => import('@/components/plugins/voting/VotingManager')
          },
          {
            path: "lottery", // 抽奖配置
            component: () => import('@/components/plugins/lottery/LotteryManager')
          },
          {
            path: "lottery_record", // 抽奖记录
            component: () => import('@/components/plugins/lottery/LotteryRecord')
          },
          {
            path: "flop", // 抽奖记录
            component: () => import('@/components/plugins/flop/FlopManager')
          },
          {
            path: "flop_record", // 抽奖记录
            component: () => import('@/components/plugins/flop/FlopRecord')
          },
          {
            path: "caption",
            component: () => import('@/components/plugins/caption/CaptionManager')
          },
          {
            path: "song",
            component: () => import('@/components/plugins/song/SongManager.vue')
          },
          {
            path: "song_record",
            component: () => import('@/components/plugins/song/SongList.vue')
          }
        ],
      },
      {
        path: '/custom',
        children: [
          {
            path: 'kpi',
            component: () => import('@/components/customize/kpi/KpiManager')
          }
        ],
      },
      {
        path: '/coin_sys',
        children: [
          {
            path: "income",
            component: () => import('@/components/module/coinsystem/income/IncomeManager')
          },
          {
            path: "base_conf",
            component: () => import('@/components/module/coinsystem/BaseConfig')
          },
          {
            path: "coin_conf",
            component: () => import('@/components/module/coinsystem/coin/CoinManager')
          },
          {
            path: "reward_conf",
            component: () => import('@/components/module/coinsystem/reward/RewardConfig')
          },
          {
            path: "exchange",
            component: () => import('@/components/module/coinsystem/exchange/ExchangeManager')
          },
          {
            path: "address",
            component: () => import('@/components/module/coinsystem/address/AddressManager')
          }

        ],
      },
      {
        path: '/backend',
        children: [
          {
            path: "bili_gift",
            component: () => import('@/components/module/backend/GiftConfig')
          },
          {
            path: "user_config",
            component: () => import('@/components/module/backend/UserConfig')
          },
        ],
      }

    ]
  },

  {
    path: '/tool',
    component: toolPage,
    children: [
      {
        path: 'jiaban/:code',
        component: () => import("@/components/plugins/jiaban/JiabanLive")
      },
      {
        path: 'voting/:code',
        component: () => import("@/components/plugins/voting/VotingLive")
      },
      {
        path: "lottery/:code", // 抽奖
        component: () => import('@/components/plugins/lottery/LotteryLive')
      },
      {
        path: "flop/:code", // 抽奖记录
        component: () => import('@/components/plugins/flop/FlopLive')
      },
      {
        path: "caption/:code",
        component: () => import('@/components/plugins/caption/CaptionLive')
      },
      {
        path: "song/:code",
        component: () => import('@/components/plugins/song/SongLive')
      }
    ]
  },
  {
    path: '/preview',
    component: previewView,
    children: [
      {
        path: 'jiaban/:code',
        component: () => import("@/components/plugins/jiaban/JiabanPreview")
      },
      {
        path: 'voting/:code',
        component: () => import("@/components/plugins/voting/VotingPreview")
      },
      {
        path: "lottery/:code", // 抽奖
        component: () => import('@/components/plugins/lottery/LotteryPreview')
      },
      {
        path: "flop/:code", // 抽奖记录
        component: () => import('@/components/plugins/flop/FlopPreview')
      },
      {
        path: "caption/:code",
        component: () => import('@/components/plugins/caption/CaptionPreview')
      }
    ]
  },
  {
    path: '/cus',
    component: toolPage,
    children: [
      {
        path: 'kpi/:code',
        component: () => import("@/components/customize/kpi/KpiLive")
      },
    ]
  },

]

export const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes, // `route: route` 的缩写
})

export default router;