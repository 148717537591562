import {createApp} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from './router'
import store from './store'
import cookies from 'vue-cookies'
import * as ElIcons from '@element-plus/icons'
import '@/assets/font/font.css';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

const app = createApp(App)
for (const name in ElIcons) {
    app.component(name, (ElIcons)[name])
}

app.config.globalProperties.$cookies = cookies
app.use(router)
app.use(store)
app.use(ElementPlus, {
    locale: zhCn, size: 'small'
})

app.mount('#app')