import {createStore} from 'vuex';


export default createStore({
    state: {
        room_id: null,
        token: null,
        room: null,
        coin_name: null,
        gift_list: null
    },
    getters: {
        room_id: state => state.room_id,
        room: state => state.room,
        coinName: state => state.room ? state.room.coinName : null,
        code: state => state.code,
        gift_list: state => state.gift_list ? state.gift_list : null,
    },
    mutations: {
        set_room(state, room) {
            state.room = room;
            state.room_id = room.id
            state.code = room.code
        },
        set_token(state, token) {
            state.token = token;
        },
        set_gift_list(state, gift_list) {
            state.gift_list = gift_list
        },
        clean(state) {
            state.room = null;
            state.token = null;
            state.room_id = null;
            state.coin_name = null;
        }
    },
});